import React, { Component } from 'react';
import './Contact.css';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

class Contact extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      email: '',
      message: '',
      successMessage: ''
    };
  }

  changeHandler = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ ...this.state, [name]: value });
  };

  isFormValid = () => {
    const { name, email, message } = this.state;
    return name.trim() !== '' && email.trim() !== '' && message.trim() !== '';
  };

  submitHandler = (event) => {
    event.preventDefault();
    if (this.isFormValid()) {
      this.postData();
    }
  };

  postData = () => {
    axios.post('https://ld40lca5hc.execute-api.us-east-1.amazonaws.com/Prod/crochet-contact', {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message
    })
    .then((response) => {
      if (response.status === 200) {
        this.setState({
          name: '',
          email: '',
          message: '',
          successMessage: 'Message has been Submitted. Your query will be responded shortly!',
        });
      } else {
        this.setState({ name: '', email: '', message: '', successMessage: 'Error Occurred. Please try again later.' });
      }
      console.log(response);
    })
    .catch(error => {
      this.setState({ name: '', email: '', message: '', successMessage: 'Error Occurred. Please try again later.' });
    });
  };

  render() {
    const { name, email, message, successMessage } = this.state;

    return (
      <div className="contact-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 d-flex">
              <div className="contact-image">
                <img src="https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/coasters/Coaster_Green(Flower)_14.99_1QaSJSGuk3Vz1JU9ODnBn2E9.jpg" alt="test" className="silh"></img>
              </div>
              <div className="contact-form-container">
                <form className="contact-form" onSubmit={this.submitHandler}>
                  <div className="form-group">
                    <label htmlFor="name"><strong>NAME</strong></label>
                    <input
                      onChange={this.changeHandler}
                      name="name"
                      type="text"
                      className="form-control wider-input"
                      value={name}
                    />
                  </div>
                  <div className="form-group wider-input">
                    <label htmlFor="exampleInputEmail1"><strong>EMAIL ADDRESS</strong></label>
                    <input
                      onChange={this.changeHandler}
                      name="email"
                      type="email"
                      className="form-control wider-input"
                      value={email}
                    />
                  </div>
                  <div className="form-group wider-input">
                    <label htmlFor="message"><strong>MESSAGE</strong></label>
                    <textarea
                      onChange={this.changeHandler}
                      name="message"
                      type="text"
                      className="form-control wider-input"
                      value={message}
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary w-40 submit-button"
                    disabled={!this.isFormValid()}
                  >
                    Submit
                  </button>
                  <br />
                  <br />
                </form>
                <span className="text-success">{successMessage}</span>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;