import React from "react";
import { Container } from "react-bootstrap";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import CartProvider from "./CartContext";
import NavbarComponent from "./components/Navbar";
import Checkout from "./components/Checkout";

import Home from "./Pages/Home";
import Store from "./Pages/Store";
import Success from "./Pages/Success";
import Cancel from "./Pages/Cancel";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Categories from "./Pages/Categories";
import Bags from "./Pages/Bags";
import Winter from "./Pages/Winter";
import CarDecor from "./Pages/CarDecor";
import Coasters from "./Pages/Coasters";
import KeyChains from "./Pages/KeyChains";
import AllProducts from "./Pages/AllProducts";
import Error from "./Pages/Error";
import { useEffect } from "react";
import { fetchData } from "./ProductsStore";
import './Pages/App.css';
import axios from 'axios';

const App =  () => {
  useEffect(() => {

    fetchData();

  }, []);
  return (
    <><div className="background-overlay"></div><><>
      <CartProvider>
        <BrowserRouter>
          <NavbarComponent />
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <Container>
            <Routes>
              <Route path="/" index element={<Categories />} />
              <Route path="home" element={<Categories />} />
              <Route path="store" element={<Categories />} />
              <Route path="coasters" element={<Coasters />} />
              <Route path="bags" element={<Bags />} />
              <Route path="winter" element={<Winter />} />
              <Route path="cardecor" element={<CarDecor />} />
              <Route path="keychains" element={<KeyChains />} />
              <Route path="about" element={<About />} />
              <Route path="success" element={<Success />} />
              <Route path="cancel" element={<Cancel />} />
              <Route path="error" element={<Error />} />
              <Route path="contact" element={<Contact />} />
              <Route path="allproducts" element={<AllProducts />} /> 
            </Routes>
          </Container>
        </BrowserRouter>
      </CartProvider>
    </></></>
  );
}

export default App;
