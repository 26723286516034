import React, { useState, useContext } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Checkout from './Checkout';
import { CartContext } from '../CartContext';
import './Navbar.css';

function NavbarComponent() {
  const cart = useContext(CartContext);
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => setExpanded(!expanded);
  const handleClose = () => setExpanded(false);

  return (
    <>
      <Navbar expanded={expanded} onToggle={handleToggle} expand="lg" className="custom-navbar">
        <Container>
          <Navbar.Brand>
            <img className='logo-image' src="/images/cozyloops_new_logo.jpg" alt="logo" width={300} height={100} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <NavLink className="nav-link" to="/home" onClick={handleClose}>
                Home
              </NavLink>
              <NavLink className="nav-link" to="/coasters" onClick={handleClose}>
                Coasters
              </NavLink>
              <NavLink className="nav-link" to="/bags" onClick={handleClose}>
                <Nav.Item>Bags & Pouches</Nav.Item>
              </NavLink>
              <NavLink className="nav-link" to="/winter" onClick={handleClose}>
                <Nav.Item>Winter Accessories</Nav.Item>
              </NavLink>
              <NavLink className="nav-link" to="/cardecor" onClick={handleClose}>
                <Nav.Item>Car Decors & Key Chains</Nav.Item>
              </NavLink>
              <NavLink className="nav-link" to="/about" onClick={handleClose}>
              <Nav.Item>About</Nav.Item>
              </NavLink>
              <NavLink className="nav-link" to="/contact" onClick={handleClose}>
              <Nav.Item>Contact</Nav.Item>
              </NavLink>
            </Nav>
          </Navbar.Collapse>
          <Checkout />
          <div className="moving-banner">
            <strong>Limited Offer:</strong> Get 10% off using Coupon Code: <strong>COZYLOOPS-MAR25</strong> 
           </div> 
        </Container>
      </Navbar>
    </>
    
  );
}

export default NavbarComponent;