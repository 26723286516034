import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Success.css'; // Ensure you have the CSS file for styling

const Success = () => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(true);
  const [orderNumber, setOrderNumber] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const orderNumber = queryParams.get('orderNumber');
    setOrderNumber(orderNumber);
  }, [location.search]);

  const handleCloseOverlay = () => {
    setIsOverlayOpen(false);
    navigate('/'); // Redirect to home page
  };

  const handleContinueShopping = () => {
    navigate('/allproducts'); // Redirect to shopping page
  };

  if (!isOverlayOpen) {
    return null;
  }

  return (
    <div className="overlay">
      <div className="order-summary-overlay">
        <button className="close-button" onClick={handleCloseOverlay}>×</button>
        <div className="banner">
          <h1>Thank you for your order!</h1>
          <h4>Your order number is: <span className="order-number">{orderNumber}</span></h4>
          <p>Order Receipt has been sent to the email provided.</p>
          <button className="continue-shopping-button" onClick={handleContinueShopping}>Continue Shopping</button>
        </div>
      </div>
    </div>
  );
};

export default Success;