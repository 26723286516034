import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import './Categories.css'; // Ensure you have the CSS file for styling

class Categories extends React.Component {
  render() {
    return (
      <div className="categories-page">
        <div className="welcome-section">
          <div className="welcome-text">
            <h1 className="welcome-message">
              Welcome to CozyLoops.com! <br /> Embrace the warmth of elegant crochet creations.
            </h1>
            <br />
            <br />
            <div style={{ alignSelf: 'flex-end' }}>
              <Link to="/allproducts">
                <Button variant="primary" className="shop-now-button">Shop Now</Button>
              </Link>
            </div>
          </div>
          <div className="welcome-image">
            <img src="/images/Crochet_Banner.jpeg" alt="Banner" className="banner-image" />
          </div>
        </div>
        <div className="categories-section">
          <h2 className="categories-title">Shop by Featured Categories</h2>
          <div className="categories-container">
            <div className="category-item">
              <div className="image-name">Coasters</div>
              <div className="image-container">
                <Link to="/coasters" style={{ textDecoration: 'none' }} className="link">
                  <img src="https://francobucket1.s3.amazonaws.com/crochetpics/coasters/Coaster_Red_14.99_1QaSJTGuk3Vz1JU9FIzMoaIq.jpg" alt="coasters" className="category-image"></img>
                </Link>
              </div>
            </div>
            <div className="category-item">
              <div className="image-name">Bags & Pouches</div>
              <div className="image-container">
                <Link to="/bags" style={{ textDecoration: 'none' }} className="link">
                  <img src="/images/Bags.jpeg" alt="bags" className="category-image"></img>
                </Link>
              </div>
            </div>
            <div className="category-item">
              <div className="image-name">Winter Accessories</div>
              <div className="image-container">
                <Link to="/winter" style={{ textDecoration: 'none' }} className="link">
                  <img src="/images/winter.jpeg" alt="winter" className="category-image"></img>
                </Link>
              </div>
            </div>
            <div className="category-item">
              <div className="image-name">Car Decor & Key Chains</div>
              <div className="image-container">
                <Link to="/cardecor" style={{ textDecoration: 'none' }} className="link">
                  <img src="/images/cardecor.jpeg" alt="cardecor" className="category-image"></img>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Categories;