import React from "react";
import { Card, Button, Form, Row, Modal, ModalBody, ModalHeader, Carousel } from "react-bootstrap";
import { CartContext } from "../CartContext";
import { useContext, useState } from "react";
import DefaultImage from "../images/ProductStoreImage.png";
import './ProductCard.css';

function ProductCard(props) {
  const product = props.product;
  const cart = useContext(CartContext);
  const productQuantity = cart.getProductQuantity(product.id);
  const isOutOfStock = product.stock < 1;
  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  // Ensure product.price is a number

  // Determine the image class based on the product description
  const imageClass = (product.description === 'Category: Bags & Pouches' || product.description === 'Category: Winter Accessories') ? 'product-image-bags' : 'product-image';

  // Sample images array for carousel
  const images = product.altimages && product.altimages.length > 0 ? product.altimages : [product.imgUrl || DefaultImage];


  return (
    <>
      <Card className="product-card">
        <Card.Img 
          variant="top" 
          src={product?.imgUrl || DefaultImage} 
          className={imageClass}
          onClick={handleOpenModal} 
          title="Click to enlarge image" 
        />
        <Card.Body className="product-info">
          <Card.Title className="product-title">{product.title}</Card.Title>
          <Card.Text className="product-description">{product.description}</Card.Text>
          <Card.Text className="product-price">${product.price}</Card.Text>
          {productQuantity > 0 ? (
            <>
              <Form as={Row}>
              </Form>
              <Button className="remove-from-cart-button" variant="danger" onClick={() => cart.deleteFromCart(product.id)}>
                Remove from cart
              </Button>
            </>
          ) : ( 
            isOutOfStock ?           
            <Form.Label column="true" sm="25">
              <h6 className="out-of-stock">Out Of Stock</h6>
            </Form.Label>: 
            <Button className="add-to-cart-button" variant="primary" onClick={() => cart.addOneToCart(product.id)}>
              Add To Cart
            </Button>
          )}
        </Card.Body>
      </Card>
      <Modal show={showModal} onHide={handleCloseModal}>
        <ModalHeader closeButton>
          <Modal.Title>{product.title}</Modal.Title>
        </ModalHeader>
        <ModalBody>
        <Carousel controls={images.length > 1}>
            {images.map((image, index) => (
              <Carousel.Item key={index}>
                <img src={image} alt={`Slide ${index}`} className="modal-image" />
              </Carousel.Item>
            ))}
          </Carousel>
        </ModalBody>
      </Modal>
    </>
  );
}

export default ProductCard;