import React from "react";
import Button from "react-bootstrap/Button";
import { CartContext } from "../CartContext";
import { useContext } from "react";
import { getProductData } from "../ProductsStore";
import './CartProduct.css';

function CartProduct(props) {
  const cart = useContext(CartContext);
  const id = props.id;
  const quantity = props.quantity;
  const productData = getProductData(id);

  return (
    <>
     <div className="cart-product">
     <img src={productData.thumbnail} alt={productData.title} className="product-thumbnail" />
      <span className="cart-product-title">{productData.title}</span>
      <span class='cart-product-quantity'>quantity: {quantity} </span>
      <span  class='cart-product-quantity'>total price: ${(quantity * productData.price).toFixed(2)}</span>
      <Button size="sm" onClick={() => cart.deleteFromCart(id)}>
        Remove
      </Button>
      <hr></hr>
      </div>
    </>
  );
}

export default CartProduct;
